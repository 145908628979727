#mask {
    display: none;
    position: fixed;
    width: 100%;
    max-width: 10.24rem;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0,0,0,0.6);
    z-index: 2147483647;
    text-align: right;
}
#qqmask {
    display: none;
    position: fixed;
    width: 100%;
    max-width: 10.24rem;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: rgba(0,0,0,0.6);
    z-index: 2147483647;
    text-align: right;
}

.step {
    position: absolute;
    -webkit-box-radius: 50%;
    border-radius: 50%;
    background-color: #ff5046;
}

.step-l {    
    width: 0.73rem;
    height: 0.31rem;
    top: 0.69333333rem;
    right: 1.12rem; 
}

.step-m {    
    width: 0.45333333rem;
    height: 0.18666666rem;
    top: 0.33333333rem;
    right: 0.90666666rem; 
}

.step-s {    
    width: 0.25333333rem;
    height: 0.10666666rem;
    top: 0.09333333rem;
    right: 0.70666666rem; 
}

.bubble-container {
    position: relative;
    display: -webkit-box;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    width: 4.65333333rem;
    height: 1.86666666rem;
    margin-bottom: 0.8rem;
    margin-left: 4.533333333rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    line-height: 0.48rem;
    font-size: 0.37333333rem;
    color: #fff;
    text-align: center;
    background-color: #ff5046;
}

.first-bubble {
    margin-top: 1.24rem;
}

.step-num {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.66666666rem;
    height: 0.66666666rem;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    line-height: 0.66666666rem;
    text-align: center;
    color: #ff5046;
    font-weight: bold;
    font-family: 'Helvetica Neue' cursive;
    background-color: #fff;
}